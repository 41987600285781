@import './colors';
@import './mixins';

main {

	.hero {
		background-color: @color_black;
		max-width: 2500px;
		margin: 0 auto;
		.wrap {
			height: 500px;
			display: flex;
			.l {
				display: flex;
				.mixin_background_diagonal(@color_cyan, @color_black, 42%);
				width: 578px;
				height: 100%;
				h2 {
					color: #FFFFFF;
					margin: auto 0 30px 50px;
					.mixin_font_work_sans_extra_bold();
					text-transform: uppercase;
					font-size: 48px;
					width: 100%;
				}
			}
			.r {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				width: 70%;
				height: 100%;
				h3 {
					color: #FFFFFF;
					margin: auto 50px 30px 0;
					.mixin_font_work_sans_extra_bold();
					text-transform: uppercase;
					font-size: 48px;
					width: 100%;
				}
			}
		}
		&.magenta .wrap .l {
			.mixin_background_diagonal(@color_magenta, @color_black, 42%);
		}
		&.yellow .wrap .l {
			.mixin_background_diagonal(@color_yellow, @color_black, 42%);
			h2 {
				color: @color_black;
			}
		}
		&.small {
			.wrap {
				height: 300px;
				.l {
					.mixin_background_diagonal(@color_cyan, @color_black, 56%);
				}
				.r .contact-wrap {
					display: none;
				}
			}
			&.magenta .wrap .l {
				.mixin_background_diagonal(@color_magenta, @color_black, 56%);
			}
			&.yellow .wrap .l {
				.mixin_background_diagonal(@color_yellow, @color_black, 56%);
			}
		}
	}
}

@media (max-width: 1470px) {
	main .hero .wrap {
		height: 450px;
	}
	main .hero .wrap .l h2 {
		margin: auto 0 30px 30px;
		font-size: 42px;
	}
	main .hero .wrap .r h3 {
		margin: auto 30px 30px 0;
		font-size: 42px;
	}
}

@media (max-width: 1199px) {
	main .hero .wrap .l h2 {
		font-size: 38px;
	}
	main .hero .wrap .r h3 {
		font-size: 38px;
	}
}

@media (max-width: 991px) {
	main .hero .wrap {
		height: 350px;
		.l {
			.mixin_background_diagonal(@color_cyan, @color_black, 45%);
			width: 400px;
		}
	}
	main .hero .wrap .l h2 {
		font-size: 30px;
	}
	main .hero .wrap .r h3 {
		font-size: 30px;
	}
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
	main .hero .wrap {
		height: 200px;
		.l {
			.mixin_background_diagonal(@color_cyan, @color_black, 55%);
			width: 300px;
		}
	}
	main .hero .wrap .r h3 {
		display: none;
	}
	main .hero.small .wrap {
		height: 200px;
		.l {
			.mixin_background_diagonal(@color_cyan, @color_black, 50%);
			width: 100%;
		}
	}
	main .hero.small .wrap .r {
		display: none;
	}
}

@media (max-width: 500px) {
	main .hero .wrap {
		height: 200px;
		.l {
			.mixin_background_diagonal(@color_cyan, @color_black, 50%);
			width: 100%;
		}
	}
	main .hero .wrap .r {
		display: none;
	}
}