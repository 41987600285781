@import './colors';
@import './mixins';

main {

	.fact-counter {

		background-color: @color_black;
		color: #FFFFFF;

		.entries {
			text-align: center;
			width: 100%;
			max-width: 2500px;
			margin: 50px auto;
			.mixin_font_work_sans_bold();
			font-size: 24px;

			.fact-counter-entry {
				display: inline-flex;
				text-align: center;
				width: 30%;
				flex-direction: column;

				.wrap {
					display: inline-block;
					flex-direction: row;
					//font-size: 40px;
					//font-weight: 500;
					text-align: center;
					div {
						display: inline-block;
						margin: 0;
					}
				}
				.desc {
					display: inline-block;
					text-align: center;
					//font-size: 13px;
					//font-weight: 500;
					//color: #585858;

				}
			}
		}
	}
}

@media (max-width: 1199px) {
	main .fact-counter .entries {
		font-size: 21px;
	}
}

@media (max-width: 991px) {
	main .fact-counter .entries {
		font-size: 18px;
	}
}

@media (max-width: 767px) {
	main .fact-counter .entries {
		font-size: 18px;
	}
}

@media (max-width: 575px) {
	main {
		.fact-counter {
			.entries {
				text-align: center;
				width: 100%;
				margin: 70px auto 0 auto;

				.fact-counter-entry {
					display: flex;
					width: 70%;
					margin: 0 auto 50px auto;

					.wrap {
						font-size: 32px;
					}
					.desc {
						font-size: 18px;
					}
				}
			}
		}
	}
}
