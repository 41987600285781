@import "~baseVariables";
@import "~masterVariables";
//@import './colors';
//@import './fa';

body {
	font-family: "Work Sans", sans-serif;
	color: @text_fgc;
	background-color: @body_bgc;
	font-size: 16px;
	padding: 0;
	margin: 0;
}
a {
	color: inherit;
}

::-moz-selection { /* Code for Firefox */
	color: #FFFFFF;
	background: #000000;
}

::selection {
	color: #FFFFFF;
	background: #000000;
}

.fec_anchor {
	display: block;
	position: absolute;
	margin-top: -180px;
	text-decoration: none;
	color: inherit;
	font-size: inherit;
	&:hover {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

.base_modal_modal {
	z-index:1100
}
.container-fluid {
	max-width: 2500px;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.error_container {
	background-color: #FFFFFF;

	.error-message {
		padding-top: 8%;
		text-align: center;
		color: #000000;

		img {
			margin-bottom: 35px;
		}

		h1 {
			font-size: 32px;
		}

		h2 {
			font-size: 18px;
		}

		p {
			font-size: 16px;
		}
	}
}

.cookie-policy-bar {
	border-top: 1px solid #FFFFFF;
	background-color: @color_black;
	padding: 10px 17px;
	color: #FFFFFF;
	font-size: 12px;
	text-align: center;
	display:none;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1000;

	a {
		color: inherit;
		text-decoration: underline;
	}
	button {
		border: 1px solid #FFFFFF;
		background-color: #000000;
		color: #FFFFFF;
		padding: 3px 7px;
		border-radius: 3px;
		margin-left: 15px;
		cursor: pointer;
		font-weight: bold;
	}
}

@import './header';
@import './main';
@import './hero';
//@import './hero-parallax';
@import './block';
@import './contact';
@import './footer';