@import '../../vendor/fontawesome-5.15.4/css/brands.css';
@import '../../vendor/fontawesome-5.15.4/css/regular.css';
@import '../../vendor/fontawesome-5.15.4/css/solid.css';
@import (reference, less) '../../vendor/fontawesome-5.15.4/css/all.css';

.fib											:extend(.fab){}
.fir											:extend(.far){}
.fis											:extend(.fas){}

// B R A N D S
//.fi-facebook-f						:extend(.fa-facebook-f all){}
//.fa-facebook-f						:extend(.fa-facebook-f all){}
//.fi-twitter								:extend(.fa-twitter all){}
//.fa-twitter								:extend(.fa-twitter all){}
//.fi-whatsapp							:extend(.fa-whatsapp all){}
//.fi-pinterest-p						:extend(.fa-pinterest-p all){}
//.fa-pinterest-p						:extend(.fa-pinterest-p all){}
//.fi-linkedin-in						:extend(.fa-linkedin-in all){}
//.fa-linkedin-in						:extend(.fa-linkedin-in all){}
//.fi-instagram							:extend(.fa-instagram all){}
//.fi-youtube								:extend(.fa-youtube all){}

// R E G U L A R
.fi-bars										:extend(.fa-bars all){}
//.fi-arrow-right							:extend(.fa-arrow-right all){}
.fi-envelope								:extend(.fa-envelope all){}


// S O L I D
//.fi-ellipsis-v							:extend(.fa-ellipsis-v all){}
//.fi-angle-down							:extend(.fa-angle-down all){}
.fi-times										:extend(.fa-times all){}
//.fi-home										:extend(.fa-home all){}
//.fi-exclamation-triangle		:extend(.fa-exclamation-triangle all){}
//.fi-map											:extend(.fa-map-marker-alt all){}
//.fi-quote-left							:extend(.fa-quote-left all){}
//.fi-quote-right							:extend(.fa-quote-right all){}
.fi-phone										:extend(.fa-phone all){}
//.fi-file-pdf								:extend(.fa-file-pdf all){}
//.fi-file-doc								:extend(.fa-file-word all){}
//.fi-file-docx								:extend(.fa-file-word all){}
//.fi-file-csv								:extend(.fa-file-csv all){}
//.fi-file-xls								:extend(.fa-file-excel all){}
//.fi-file-xlsx								:extend(.fa-file-excel all){}

//.fi-phone-square					:extend(.fa-phone-square all){}
//.fi-envelope							:extend(.fa-envelope all){}
//.fi-arrow-right						:extend(.fa-arrow-right all){}

//.fi-check 								:extend(.fas all, .fa-check all){}
//.fi-search									:extend(.fa-search all){}
