@import './colors';

footer {
	max-width: 2500px;
	margin: 0 auto;
	background-color: @color_black;
	color: #FFFFFF;
	display: flex;
	position: relative;

	.wrap {
		padding: 50px 0;
		text-align: center;

		.claim {
			.mixin_font_work_sans_regular();
			font-size: 38px;
			text-transform: uppercase;
			line-height: 1.1;
			strong {
				.mixin_font_work_sans_bold();
			}
			margin-bottom: 15px;
		}
		.location {
			text-transform: uppercase;
			font-size: 18px;
			//margin-bottom: 15px;
		}
		.contact {
			font-size: 18px;
			margin-bottom: 20px;
			a {
				text-decoration: none;
				&:hover {
					color: @color_cyan;
				}
			}
		}
		img {
			margin-bottom: 25px;
		}
	}

	nav {
		color: #FFFFFF;
		margin-bottom: 25px;
		ul {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				padding: 0 30px 0 0;
				margin: 0 30px 0 0;
				display: inline-flex;
				border-right: 1px solid #FFFFFF;

				&:last-child {
					margin: 0;
					padding: 0;
					border: none;
				}
				a {
					display: inline-block;
					font-size: 21px;
					text-decoration: none;
					text-transform: uppercase;
					line-height: 1.2;
					&:hover {
						color: @color_cyan;
						text-decoration: none;
					}
				}
				&:hover, &.active {
					a {
						color: @color_cyan;
						text-decoration: none;
					}
				}
			}
		}
	}
	.copyright {
		font-size: 18px;
		margin-bottom: 25px;
		a {
			text-decoration: none;
			&:hover {
				color: @color_cyan;
			}
		}
	}
}

@media (max-width: 1470px) {
	footer .wrap {
		.claim {
			font-size: 32px;
		}
		.location, .contact {
			font-size: 17px;
		}
	}
	footer nav ul li a {
		font-size: 18px;
	}
	footer .copyright {
		font-size: 17px;
	}
}

@media (max-width: 1280px) {

}

@media (max-width: 991px) {
	footer .wrap {
		.claim {
			font-size: 28px;
		}
		.location, .contact {
			font-size: 16px;
		}
	}
	footer nav ul li a {
		font-size: 18px;
	}
	footer .copyright {
		font-size: 16px;
	}
	footer .wrap img {
		width: 150px;
	}
}

//@media (max-width: 768px) {
//}
//@media (max-width: 575px) {
//}
@media (max-width: 575px) {
	footer .wrap {
		.claim {
			font-size: 24px;
		}
	}
	footer nav ul li {
		padding: 0 13px 0 0;
		margin: 0 10px 0 0;
		a {
			font-size: 16px;
		}
	}
	footer .copyright {
		font-size: 14px;
	}
}

