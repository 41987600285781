@import './colors';
@import './mixins';

main {

	.contact {
		margin-bottom: 50px;

		form {
			text-align: left;
			max-width: 80%;
			padding: 0 50px;
			margin: 0 auto 0 auto;

			.title-wrap {
				margin-bottom: 25px;

				h2 {
					.mixin_font_work_sans_extra_bold();
					font-size: 48px;
				}
				h3 {
					.mixin_font_work_sans_bold();
					font-size: 28px;
					margin: 10px 0 0 0;
					&.pre {
						margin: 0 0 5px 0;
					}
				}
			}
			.text {
				font-size: 18px;
				margin: 0 0 25px 0;
			}
		}

		#frmContactSuccess, #frmContactError {
			display: none;
			padding: 20px;
			margin: 15px 0;
			text-align: center;
		}
		#frmContactSuccess {
			background-color: #d4edda;
			color: #004F00;
			border: 1px solid #004F00;
		}
		#frmContactError {
			background-color: #f8d7da;
			color: #8B0000;
			border: 1px solid #8B0000;
			a {
				color: #8B0000;
				text-decoration: underline;
				font-weight: bold;
			}
		}

		label {
			font-size: 18px;
			//text-transform: uppercase;
			margin-bottom: 1px;
			color: @color_black;

			&.checkboxPrivacyText {
				text-transform: none;
				line-height: 120%;

				a {
					color: inherit;
					text-decoration: underline;
				}
			}
		}

		.form-control {

			height: 48px;
			color: @color_black;
			border: 1px solid @color_black;
			border-radius: 0;
			transition: none;
			background-clip: border-box;
			background-color: #FFFFFF;

			&.is-invalid {
				border: 2px solid #8B0000;
			}
		}
		select.form-control {
			appearance: auto;
			-webkit-appearance: auto;
			-moz-appearance: auto;
			-ms-appearance: auto;
			margin-bottom: 10px;
		}
		.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
			color: #8B0000;
		}
		textarea.form-control {
			height: 140px;
		}
		.form-group {
			input {
				margin-bottom: 10px;
			}
		}
		.form-check-label {
			cursor: pointer;
			color: #404040;
			line-height: 1.2;
			margin-bottom: 10px;
		}

		#checkBoxPrivacy {
			margin-top: 0;
			border: 2px solid @color_black;

			&.is-invalid {
				outline: 2px solid #8B0000;
			}
		}
		.checkboxPrivacyText {
			font-size: 16px;
		}
		#district {
			position: absolute;
			width: 100px;
			left: -1000px;
		}

		.disclaimer {
			margin-top: 25px;
			color: #404040;
		}
		.button-wrap {
			text-align: right;
		}
		button {
			display: flex;
			flex-direction: row;
			background-color: @color_magenta;
			border: 0;
			color: #FFFFFF;
			margin: 25px 0 0 auto;
			padding: 0;
			height: 48px;

			div {
				display: inline-block;
				.mixin_background_diagonal(#FFFFFF, @color_magenta, 48px);
				width: 65px;
				height: 48px;
			}
			span {
				display: inline-block;
				line-height: 48px;
				height: 48px;
				.mixin_font_work_sans_medium();
				font-size: 21px;
				text-transform: uppercase;
				margin: 0 30px 0 10px;
			}
			&.active, &:hover {
				color: @color_black;
				//div {
				//	.mixin_background_diagonal(#FFFFFF, @color_black, 48px);
				//}
			}
		}

		textarea:focus,
		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="datetime"]:focus,
		input[type="datetime-local"]:focus,
		input[type="date"]:focus,
		input[type="month"]:focus,
		input[type="time"]:focus,
		input[type="week"]:focus,
		input[type="number"]:focus,
		input[type="email"]:focus,
		input[type="url"]:focus,
		input[type="search"]:focus,
		input[type="tel"]:focus,
		input[type="color"]:focus,
		.uneditable-input:focus {
			border-color: rgba(126, 239, 104, 0.8);
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(143, 110, 143, 0.6);
			outline: 0 none;
		}
	}
}

@media (max-width: 1470px) {
	main .contact form .title-wrap h2 {
		font-size: 42px;
	}
	main .contact label {
		font-size: 17px;
	}
	main .contact form {
		max-width: 100%;
		padding: 0 30px;
	}
}

@media (max-width: 1280px) {

	main .contact form .title-wrap h2 {
		font-size: 38px;
	}
	main .contact label {
		font-size: 16px;
	}
}