@import './colors';

header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: #FFFFFF;

	.bg {
		display: block;
		max-width: 2500px;
		margin: 0 auto;
	}
	#butMobileNavClose {
		display: none;
		font-style: normal;
		line-height: 1;
		margin-left: 4px;
		height: 160px;
	}

	.wrap {
		display: flex;
	}
	.logo {
		position: relative;
		left: 30px;
		top: 15px;
		display: inline-block;
		//margin-bottom: 20px;
	}

	.nav-wrap {
		flex-grow: 1;
		text-align: right;
		display: flex;

		.nav-mobile {
			display: none;
			i {
				font-size: 32px;
				color: @color_black;
				cursor: pointer;
			}
		}
		nav {
			display: flex;
			margin: auto 40px 0 auto;
			height: 160px;
			align-items: flex-end;
			ol {
				display: flex;
				align-items: center;
				list-style: none;
				flex-wrap: wrap;
				padding: 0;

				&.additionalMobileNav {
					display: none;
				}

				&>li {
					display: inline-block;
					margin-left: 40px;
					color: @color_black;
					border-bottom: 4px solid #FFFFFF;

					&:first-child {
						margin-left: 0;
					}

					&.active, &:hover {
						border-bottom: 4px solid @color_black;
						&.cyan {
							border-color: @color_cyan;
						}
						&.magenta {
							border-color: @color_magenta;
						}
						&.yellow {
							border-color: @color_yellow;
						}
					}

					a {
						.mixin_font_work_sans_bold();
						font-size: 21px;
						//letter-spacing: 3.6px;
						text-transform: uppercase;
						display: inline-block;
						color: inherit;
						padding: 0;
						text-decoration: none;
					}
				}
			}
		}
	}

	.contact-flaps {
		position: fixed;
		top: 210px;
		right: 0;
		//width: 100%;
		color: #FFFFFF;
		flex-direction: column;

		.wrap {
			//max-width: 2500px;
			display: flex;
			//margin: 0 auto;
			text-align: right;
			flex-direction: column;

			div {
				text-align: right;
				display: inline-flex;
				margin: 0 0 0 auto;
				a {
					text-decoration: none;
					.mixin_font_work_sans_bold();
					font-size: 24px;
					padding: 20px 50px 20px 100px;
					display: inline-block;
					width: fit-content;
					line-height: 24px;
					&:hover {
						color: @color_black;
					}
					i {
						display: none;
						font-size: 24px;
					}
					i.fi-envelope {
						font-size: 26px;
					}
				}
				&:first-child {
					a {
						background-color: @color_cyan;
						.mixin_background_diagonal(transparent, @color_cyan, 50px);
					}
					margin-bottom: 15px;
				}
				&:last-child {
					a {
						background-color: @color_magenta;
						.mixin_background_diagonal(transparent, @color_magenta, 50px);
					}
				}
			}
		}
	}
	&.scrolling {
		border-bottom: 2px solid @color_black;

		.contact-flaps {
			.wrap {
				div a {

					span { display: none; }
					i { display: block; }
					filter: drop-shadow(5px 5px 5px @color_black);
					&:hover {
						color: #FFFFFF;
						span { display: block; }
						i { display: none; }
					}
				}
			}
		}
	}
}



//@media (max-width: 1670px) {
//}
//@media (max-width: 1550px) {
//}

@media (max-width: 1470px) {
	header {
		.logo {
			max-width: 110px;
			top: 0;
			left: 0;
			margin: 5px 0 5px 13px;
		}

		.nav-wrap {
			width: auto;
			flex-grow: 1;

			.nav-mobile {
				display: block;
				position: absolute;
				right: 30px;
				top: 35px;
				z-index: 30;
				i {
					font-size: 38px;
					color: @color_black;
					cursor: pointer;
				}
			}

			nav {
				overflow-y: auto;
				position: absolute;
				top: 106px;
				left: 0;
				z-index: 10;
				background-color: #FFFFFF;
				display: none;
				margin: 0;
				padding: 25px 0 25px 0;
				width: 100%;
				height: ~"calc(100vh - 110px)";
				border-top: 1px solid @color_cyan;

				&>ol {
					display: flex;
					flex-direction: column;
					height: auto;
					margin: 10px 0 0 0;

					&.additionalMobileNav {
						display: inline-block;
						margin: 0 0 0 30px;
						&>li {
							a {
								color: #888;
							}
						}
					}

					&>li {
						display: inline-block;
						margin: 15px auto 0 auto;
						font-weight: bold;
						text-align: center;
						padding: 0;
						&:first-child {
							margin: 15px auto 0 auto;
						}
						a {
							font-size: 18px;
							width: 100%;
						}

						div.s {
							position: relative;
							display: block;
							background-color: #FFFFFF;
							padding: 10px 0 20px 0;
							border-top: none;

							ol {
								//flex-direction: column;
								padding: 0;
								align-items: center;

								li {
									border-color: #FFFFFF;
									margin: 0 0 15px 0;

									a {
										font-size: 18px;
										line-height: 24px;
										font-weight: 400;
										letter-spacing: 0;
										//padding: 8px 0;
										display: block;
										color: #000000;
										text-transform: none;
									}
									&:last-child {
										margin: 0;
									}
									&:hover {
										border-color: #000;
									}
								}
							}
						}
					}
				}
			}
		}

		.contact-flaps {
			top: 150px;
			.wrap div a {
				font-size: 21px;
				padding: 12px 30px 12px 80px;
			}
		}
	}
}

@media (max-width: 991px) {
	header .contact-flaps .wrap div a {
		font-size: 18px;
		padding: 12px 30px 12px 80px;
	}
}

@media (max-width: 575px) {
	header {
		.contact-flaps {
			top: 145px;
			.wrap {
				div a {
					span { display: none; }
					i { display: block; }
					&:hover {
						color: #FFFFFF;
						span { display: block; }
						i { display: none; }
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	header {
		.contact-flaps {
			display: none;
		}
	}
}