// MIXIN
.mixin_colorizeBlock(@color) {
	.bg {
		background-color: @color;
	}
}

.mixin_button() {
	cursor: pointer;
	background-color: #000000;
	color: #FFFFFF;
	display: inline-block;
	margin: 0;
	font-size: 11px;
	line-height: 25px;
	letter-spacing: .3em;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
	padding: 14px 42px;
	//border: none;
}

.mixin_font_work_sans_thin {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 100;
	font-style: normal;
}
.mixin_font_work_sans_extra_light {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 200;
	font-style: normal;
}
.mixin_font_work_sans_light {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 300;
	font-style: normal;
}
.mixin_font_work_sans_regular {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}
.mixin_font_work_sans_medium {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}
.mixin_font_work_sans_semi_bold {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 600;
	font-style: normal;
}
.mixin_font_work_sans_bold {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 700;
	font-style: normal;
}
.mixin_font_work_sans_extra_bold {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 800;
	font-style: normal;
}
.mixin_font_work_sans_black {
	font-family: "Work Sans", sans-serif;
	font-optical-sizing: auto;
	font-weight: 900;
	font-style: normal;
}

.mixin_background_diagonal(@colorLeft, @colorRight, @width) {
	@degLine: 50deg;
	@areaWidth: @width;
	background: @colorLeft;
	background: -moz-linear-gradient(@degLine, @colorLeft @areaWidth, @colorRight @areaWidth);
	background: -webkit-linear-gradient(@degLine, @colorLeft @areaWidth, @colorRight @areaWidth);
	background: linear-gradient(@degLine, @colorLeft @areaWidth, @colorRight @areaWidth);
}
