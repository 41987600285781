@import './mixins';

main {
	.block {
		color: #000;
		margin-top: 50px;
		&.centered {
			margin-left: auto;
			margin-right: auto;
			max-width: 80%;
			.block-text {
				margin-bottom: 50px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&.block-text-simple-container {
			&.no-spacing {
				margin-top: 0;
			}
		}
		&.quote-container {
			&.no-spacing-top {
				margin-top: 0;
			}
			margin-bottom: 100px;
			.content-wrap {
				&.white {
					padding: 0 50px;
					.quote-wrapper {
						padding: 0;
					}
				}
			}
		}
		&.block-portfolio {
			position: relative;
			margin-top: 0;
			margin-bottom: 50px;

			.content-wrap {
				padding-top: 0;
				.detail-data {
					display: none;
				}
			}

			.button-wrap {
				margin: 0 auto;
				max-width: 80%;
				display: flex;
				position: relative;

				.categories {
					display: inline-flex;
					margin: 0 auto;
					align-items: center;

					button {
						display: flex;
						flex-direction: row;
						border: 0;
						color: #FFFFFF;
						margin: 0 5px 50px 0;
						padding: 0;
						height: 48px;

						div {
							display: inline-block;
							width: 65px;
							height: 48px;
						}
						span {
							display: inline-block;
							line-height: 48px;
							height: 48px;
							.mixin_font_work_sans_medium();
							font-size: 21px;
							text-transform: uppercase;
							margin: 0 30px 0 10px;
						}
					}

					&.white {
						button {
							background-color: #FFFFFF;
							div {
								display: none;
							}
							span {
								color: @color_black;
							}
							&.active {
								span {
									text-decoration: underline;
								}
							}
						}
					}
					&.black {
						button {
							background-color: @color_black;
							div {
								.mixin_background_diagonal(#FFFFFF, @color_black, 48px);
							}
							&.active {
								span {
									color: @color_yellow;
								}
							}
						}
					}
					&.cyan {
						button {
							background-color: @color_cyan;
							div {
								.mixin_background_diagonal(#FFFFFF, @color_cyan, 48px);
							}
							&.active {
								background-color: @color_black;
								div {
									.mixin_background_diagonal(#FFFFFF, @color_black, 48px);
								}
							}
						}
					}
					&.magenta {
						button {
							background-color: @color_magenta;
							div {
								.mixin_background_diagonal(#FFFFFF, @color_magenta, 48px);
							}
							&.active {
								background-color: @color_black;
								div {
									.mixin_background_diagonal(#FFFFFF, @color_black, 48px);
								}
							}
						}
					}
					&.yellow {
						button {
							background-color: @color_yellow;
							div {
								.mixin_background_diagonal(#FFFFFF, @color_yellow, 48px);
							}
							span {
								color: @color_black;
							}
							&.active {
								background-color: @color_black;
								div {
									.mixin_background_diagonal(#FFFFFF, @color_black, 48px);
								}
								span {
									color: #FFFFFF;
								}
							}
						}
					}

				}
			}
			.titleBar {
				margin: 0 auto 50px auto;
				max-width: 80%;
				display: flex;
				flex-direction: row;
				background-color: #FFFFFF;
				border: 0;
				color: #FFFFFF;
				padding: 0;
				height: 48px;

				div {
					display: inline-block;
					width: 65px;
					height: 48px;
				}
				span {
					display: inline-block;
					line-height: 48px;
					height: 48px;
					.mixin_font_work_sans_medium();
					font-size: 21px;
					text-transform: uppercase;
					margin: 0 30px 0 10px;
				}
				&.white {
					div {
						display: none;
					}
					span {
						color: @color_black;
						.mixin_font_work_sans_medium();
						font-size: 32px;
						text-transform: none;
					}
				}
				&.black {
					background-color: @color_black;
					div {
						.mixin_background_diagonal(#FFFFFF, @color_black, 48px);
					}
				}
				&.cyan {
					background-color: @color_cyan;
					div {
						.mixin_background_diagonal(#FFFFFF, @color_cyan, 48px);
					}
				}
				&.magenta {
					background-color: @color_magenta;
					div {
						.mixin_background_diagonal(#FFFFFF, @color_magenta, 48px);
					}
				}
				&.yellow {
					background-color: @color_yellow;
					div {
						.mixin_background_diagonal(#FFFFFF, @color_yellow, 48px);
					}
					span {
						color: @color_black;
					}
				}
			}
			.simple-image-list {
				margin: 0 auto;
				max-width: 80%;

				img {
					margin-bottom: 25px;
				}
				&.grey-hover-imgs {
					img {
						filter: grayscale(100%) opacity(80%);
						&:hover {
							filter: none;
						}
					}
				}
			}
			.shuffle {
				margin: 0 auto;
				max-width: 80%;

				.clts {
					visibility: hidden; // hide on load to prevent a visible removal of teasers by the category shuffle script, better this is set visible be the script
					margin-bottom: 25px;

					.entry {
						display: flex;
						flex-direction: column;

						&.has-detail {
							cursor: pointer;
						}

						//img {
						//	filter: grayscale(100%) opacity(80%);
						//	&:hover {
						//		filter: none;
						//	}
						//}

						&.active {
							border: 2px solid @color_magenta;
							img {
								filter: none;
							}
						}
					}
				}
				&.grey-hover-imgs {
					img {
						filter: grayscale(100%) opacity(80%);
						&:hover {
							filter: none;
						}
					}
					.entry {
						display: flex;
						flex-direction: column;

						&.has-detail {
							cursor: pointer;
						}

						//img {
						//	filter: grayscale(100%) opacity(80%);
						//	&:hover {
						//		filter: none;
						//	}
						//}

						&.active {
							border: none;
							img {
								filter: none;
							}
						}
					}
				}
			}
			.block-image-list-wrap {
				display: none;
			}
		}
		&.portfolio-detail {
			display: none;
		}
		.head-wrap {
			display: flex;
			align-content: center;
			align-items: center;
			flex-direction: column;
			height: 100%;
			padding: 0 50px;

			.texts {
				margin: auto 0;
				width: 100%;
				h1, h2 {
					font-size: 42px;
					font-weight: 500;
					width: 100%;
					margin: 0;

				}
				h1 {
					font-size: 56px;
					line-height: 1.103em;
					letter-spacing: -.03em;
				}
				.intro {
					margin: 10px 0 0 0;
					color: #585858;
					font-weight: 500;
					font-size: 13px;
					line-height: 1.846em;
					letter-spacing: .2em;
					text-transform: uppercase;
				}
				a.button {
					.mixin_button();
					//background-color: #000000;
					//color: #FFFFFF;
					//display: inline-block;
					//margin: 0;
					//font-size: 11px;
					//line-height: 25px;
					//letter-spacing: .3em;
					//font-weight: 500;
					//text-decoration: none;
					//text-transform: uppercase;
					//padding: 14px 42px;
					&.spaced {
						margin-top: 25px;
					}
				}
			}
			&.left {
				text-align: left;
			}
			&.right {
				text-align: right;
			}
		}
		.content-wrap {

			color: #FFFFFF;

			width: 100%;
			padding: 50px;
			background-color: transparent;

			font-size: 16px;
			font-weight: 400;
			line-height: 1.647em;

			&.block-image-list-wrap {
				padding: 50px 0;
				margin-top: 100px;
			}

			p {
				margin: 10px 0;
			}

			&.white {
				background-color: #FFFFFF;
				color: @color_black;
			}
			&.cyan {
				background-color: @color_cyan;
			}
			&.magenta {
				background-color: @color_magenta;
			}
			&.yellow {
				background-color: @color_yellow;
				color: @color_black;
			}
			&.black {
				background-color:	@color_black;
			}

			ul {
				list-style-type: square;
				li {
					a {
						text-decoration: none;
						&:hover {
							text-decoration: underline;
							color: inherit;
						}
						span.fsi {
							color: inherit;
						}
					}
				}
			}

			.block-image-list {
				//margin-top: 100px;
				.wrapper {
					display: flex;
					.img {
						width: 50%;
						margin: -150px 0 50px 0;
					}
					.data {
						color: inherit;
						width: 50%;
						padding: 50px 0 0 50px;
						h2 {
							.mixin_font_work_sans_extra_bold();
							font-size: 42px;
							//margin: 25px 0;
							-ms-word-wrap: break-word;
							word-wrap: break-word;
						}
						h3 {
							.mixin_font_work_sans_bold();
							font-size: 28px;
							margin: 10px 0 0 0;
							-ms-word-wrap: break-word;
							word-wrap: break-word;
							&.pre {
								margin: 0 0 5px 0;
							}
						}
						.text {
							font-size: 20px;
							margin: 20px 0 0 0;
							ul {
								margin-left: -10px;
								li {
									line-height: 1;
									list-style: none;
									margin-bottom: 15px;
									&::before {
										content: '';
										display: inline-block;
										height: 14px;
										width: 24px;
										background-image: url(../../../public/images/square-white.svg);
										background-size: contain;
										background-repeat: no-repeat;
										margin-left: -20px;



										//color: #000000;
										//content: "\25A0";
										//font-weight: bold;
										//font-size: 35px;
										//display: inline-block;
										//width: 30px;
										//margin-left: -23px;
									}
								}

							}
						}
					}
				}
			}
			&.yellow {
				.block-image-list {
					.wrapper .data .text ul li::before {
						background-image: url(../../../public/images/square-black.svg);
					}
				}
			}

			.block-text-simple {
				color: @color_black;
				text-align: center;
				max-width: 80%;
				margin: 0 auto;
				&.left {
					text-align: left;
				}
				&.right {
					text-align: right;
				}
				h1.t1, h2.t1 {
					.mixin_font_work_sans_extra_bold();
					font-size: 48px;
					//margin: 25px 0;
					-ms-word-wrap: break-word;
					word-wrap: break-word;
				}
				h2.t2, h3.t2 {
					.mixin_font_work_sans_bold();
					font-size: 28px;
					margin: 10px 0 0 0;
					-ms-word-wrap: break-word;
					word-wrap: break-word;
					&.pre {
						margin: 0 0 5px 0;
					}
				}
				.text {
					font-size: 18px;
					margin: 20px 0 0 0;
					a:hover {
						color: @color_magenta;
					}
				}
			}

			.block-logo {
				display: inline-block;
				margin: 15px 0;
				img {
					filter: grayscale(100%);
					&:hover {
						filter: none;
					}
				}
			}
			.block-text {
				&.is-centered {
					text-align: center;
				}
				h3 {
					.mixin_font_work_sans_extra_bold();
					font-size: 48px;
					//margin: 25px 0;
					-ms-word-wrap: break-word;
					word-wrap: break-word;
				}
				h4 {
					.mixin_font_work_sans_bold();
					font-size: 28px;
					margin: 10px 0 0 0;
					-ms-word-wrap: break-word;
					word-wrap: break-word;
					&.pre {
						margin: 0 0 5px 0;
					}
				}
				.text {
					font-size: 18px;
					margin: 20px 0 0 0;
				}
				.img {
					.img-desc {
						font-style: italic;
						font-size: 13px;
					}
				}
			}
		}

		.block-button {
			a.button {
				.mixin_button();
				margin-top: 22px;
			}
		}

		.quote-wrapper {
			//color: #FFFFFF;
			text-align: center;
			padding: 50px 0;
			max-width: 80%;
			h2 {
				.mixin_font_work_sans_extra_bold();
				font-size: 48px;
			}
			//margin: 0 auto;
			.swiper-wrapper {

				font-size: 18px;
				//line-height: 1;
				blockquote {
					line-height: 2;
					margin-bottom: 50px;
					a {
						color: inherit;
						text-decoration: underline;
					}
				}
				.person {
					font-weight: bold;
					line-height: 1.2;
					a {
						color: inherit;
						text-decoration: underline;
					}
				}
			}
		}
		.swiper-pagination {
			position: absolute;
			top: 0;
			bottom: auto;
			text-align: right;
			padding: 0;
			&.title-offset {
				top: 75px;
			}
			.swiper-pagination-bullet {
				width: 15px;
				height: 15px;
				margin: 0 2px;
				border-radius: 0;
				background-color: #FFFFFF;
			}
			.swiper-pagination-bullet-active {
				background-color: #FFFFFF;
			}
		}
		&.quote-container {
			.content-wrap.white {
				.quote-wrapper {
					padding: 25px 0 0 0;
					margin-top: -15px;
				}
				.swiper-pagination {
					.swiper-pagination-bullet {
						background-color: #000000;
					}
				}

				.swiper-pagination.title-offset {
					top: 0;
				}
			}
		}
	}
}

@media (max-width: 1470px) {

	main .block .content-wrap .block-text {
		h3 {
			font-size: 42px;
		}
		h4 {
			font-size: 24px;
		}
		.text {
			font-size: 17px;
		}
	}

	main .block .quote-wrapper {
		h2 {
			font-size: 42px;
		}
		.swiper-wrapper {
			font-size: 17px;
		}
	}
	main .block.block-portfolio .button-wrap {
		margin: 0 auto;
		max-width: 100%;
		.categories button span {
			//line-height: 48px;
			//height: 48px;
			font-size: 18px;
			margin: 0 30px 0 10px;
		}
	}
	main .block .content-wrap {
		.block-text-simple {
			max-width: 90%;
			h1.t1, h2.t1 {
				font-size: 42px;
			}
			h2.t2, h3.t2 {
				font-size: 24px;
			}
			.text {
				font-size: 17px;
			}
		}
	}
	main .block .content-wrap.block-image-list-wrap {
		padding: 50px 0 0 0;
		margin-top: 50px;
	}
	main .block .content-wrap .block-image-list {
		.wrapper {
			.img {
				//width: 50%;
				margin: -100px 0 50px 0;
			}
			.data {
				padding: 0 0 0 50px;
				h3 {
					font-size: 24px;
				}
				h2 {
					font-size: 38px;
				}
				.text {
					font-size: 18px;
				}
			}
		}
	}
}

@media (max-width: 1280px) {
	main .block .quote-wrapper {
		h2 {
			font-size: 38px;
		}
		.swiper-wrapper {
			font-size: 16px;
		}
	}
	main .block.block-portfolio .button-wrap .categories {
		display: flex;
		flex-wrap: wrap;
		button {
			margin: 0 5px 20px 0;
		}
	}
	main .block.block-portfolio .shuffle {
		margin-top: 30px;
		max-width: 100%;
	}
	main .block .content-wrap {
		.block-text-simple {
			//max-width: 90%;
			h1.t1, h2.t1 {
				font-size: 38px;
			}
			h2.t2, h3.t2 {
				font-size: 21px;
			}
			.text {
				font-size: 16px;
			}
		}
	}
	main .block .content-wrap .block-image-list {
		.wrapper {
			.data {
				padding: 0 0 50px 50px;
				h3 {
					font-size: 21px;
				}
				h2 {
					font-size: 32px;
				}
				.text {
					font-size: 16px;
					ul li::before {
						//color: #000000;
						//content: "\25A0";
						//font-weight: bold;
						//font-size: 31px;
						//display: inline-block;
						//width: 25px;
						//margin-left: -23px;
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {

	main .block.block-text-simple-container {
		margin-top: 15px;
		.content-wrap {
			padding: 30px;
		}
	}
	main .block .content-wrap {
		.block-text-simple {
			max-width: 100%;
		}
	}
	main .block .content-wrap .block-text {
		h3 {
			font-size: 38px;
		}
		h4 {
			font-size: 21px;
		}
		.text {
			font-size: 16px;
		}
	}
	main .block .content-wrap.block-image-list-wrap {
		margin-top: -50px;
	}
	main .block .content-wrap .block-image-list {
		.wrapper {
			.img {
				width: 40%;
				margin: 15px 0 0 0;
			}
			.data {
				width: 60%;
			}
		}
	}
}

@media (max-width: 768px) {
	main .block .content-wrap.block-image-list-wrap {
		padding: 0;
	}
	main .block .content-wrap .block-image-list .wrapper {
		display: flex;
		flex-direction: column;
		.img {
			width: 100%;
			margin: 0 0 50px 0;
		}
		.data {
			width: 100%;
			padding: 0 30px 50px 30px;
			.text {
				font-size: 18px;
				ul li {
					line-height: 1.2;
					&::before {
						//color: #000000;
						//content: "\25A0";
						//font-weight: bold;
						//font-size: 31px;
						//display: inline-block;
						//width: 25px;
						//margin-left: -23px;
					}
				}
			}
		}
	}
	main .block .content-wrap.block-portfolio-wrap {
		padding: 30px;
	}
	main .block .quote-wrapper {
		padding: 30px 0;
		max-width: 100%;
	}
	main .block .swiper-pagination.title-offset {
		 top: 0;
	}
}

@media (max-width: 575px) {
}